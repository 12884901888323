import React, { useContext, useEffect } from "react";
import { DataContext } from "../../contexts/DataContext";
import useIsMount from "../../hooks/useIsMount";
import useScrollController from "../../hooks/useScrollController";
import {
  StyledMultiSectionLayout,
  StyledMultiSectionLayoutSection,
} from "./style";
import loadable from "@loadable/component";

const Component = loadable(props => import(`../${props.componentName}/${props.componentName}`), {
  cacheKey: props => props.componentName,
});
const createComponent = ({ component, props }, index, usePaddingLeft) => {
  return (
    <div key={index} className={usePaddingLeft ? "col-70-children" : null}>
      <Component
        componentName={component}
        {...props}
      />
    </div>
  );
};

export default ({
  sections = [],
  usePaddingLeft = false,
}) => {
  const { selectedSectionId } = useContext(DataContext);
  const { scrollToCssSelector } = useScrollController();
  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      scrollToCssSelector("#section-presentation", 64);
    }
  }, [selectedSectionId]);

  return (
    <StyledMultiSectionLayout>
      {sections.map((section, index) => {
        return <StyledMultiSectionLayoutSection key={index} id={section.id} className={section.id == selectedSectionId ? "show" : null}>
          {section.components.map((component, index) => createComponent(component, index, usePaddingLeft))}
        </StyledMultiSectionLayoutSection>;
      })}
    </StyledMultiSectionLayout>
  );
};
